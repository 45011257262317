import React, { useState, useEffect } from 'react';


function AgendaUm() {
    const [schedule, setSchedule] = useState([]);

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const response = await fetch('./welcome.json');
                if (!response.ok) {
                    throw new Error('Erro ao carregar dados');
                }
                const data = await response.json();
                setSchedule(data);
            } catch (error) {
                console.error('Erro:', error);
            }
        };

        fetchSchedule();
    }, []);

    return (
        <div class="d-flex justify-content-center align-items-center" data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
            <div class="agenda-bg" style={{ width: "900px", height: "auto", borderRadius: "50px", padding: "50px" }}>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Data</th>
                                <th style={{ width: "20%" }}>Horário</th>
                                <th style={{ width: "50%" }}>Temas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedule.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.data}</td>
                                    <td>{item.inicio} - {item.termino}</td>
                                    <td>{item.tema}<br></br><b>{item.palestrante}</b></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default AgendaUm;
