import React, { useState, useEffect } from 'react';
import Avatar from '../assets/img/avatar.jpg';
import "./palestrantes.css"

function Banner() {
    const [palestrantes, setPalestrantes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPalestrante, setSelectedPalestrante] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('./palestrantes.json');
                if (!response.ok) {
                    throw new Error('Erro ao obter os dados dos palestrantes');
                }
                const data = await response.json();
                setPalestrantes(data);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        };

        fetchData();
    }, []);

    const openModal = (palestrante) => {
        setSelectedPalestrante(palestrante);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedPalestrante(null);
    };

    return (
        <div id='Palestrantes' style={{ paddingTop: "100px", paddingBottom: "100px", backgroundColor: "#D9D9D9" }}>
            <div className="container">
                <div className="mx-auto aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000" style={{ color: "#001233" }}>
                    <p>
                        <h1 style={{ color: "#001233" }}>PALESTRANTES</h1>
                        <h6 style={{ color: "#001233" }}>CONFIRA ALGUNS DOS PALESTRANTES CONVIDADOS:</h6>
                    </p>
                </div>
            </div>
            <div className="container" style={{ paddingTop: "100px" }}>
                {palestrantes.map((palestrante, index) => (
                    <div key={index} style={{ paddingBottom: "40px" }} data-aos="fade-up" data-aos-duration="2000">
                        <div className='row palestrantes-row'>
                            <div className='col-12 col-sm-2 imgpalestrante' style={{
                                backgroundImage: `url(${palestrante.foto === null ? Avatar : palestrante.foto})`,
                                width: "320px",
                                height: "320px"
                            }}>
                                <img style={{ width: "100%" }} />
                            </div>
                            <div className='col-12 col-sm-8 d-flex flex-column justify-content-center align-items-start text-col12'
                                style={{
                                    color: "#001233",
                                    paddingLeft: "40px"
                                }}>
                                <h1 style={{ color: "#001233" }}>{palestrante.palestrante}</h1>
                                <h5 style={{ color: "#001233" }}>{palestrante.doc}</h5>
                                <h5 style={{ color: "#001233" }}>{palestrante.cargo}</h5>
                                {
                                    palestrante.minicurriculo
                                        ? (<a type="button" onClick={() => openModal(palestrante)}>Ler mais...</a>)
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && selectedPalestrante && (
                <div className="" >
                    <section className="modal vh-100" style={{ backgroundColor: "#eee", display: "block" }} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000" >

                        <div className="container py-5 h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">

                                <div className="col-md-12 col-xl-4">

                                    <div className="card" style={{ borderRadius: "15px" }}>

                                        <div className="card-body text-center">

                                            <div className="mt-3 mb-4">
                                                <img src={selectedPalestrante.foto}
                                                    className="rounded-circle img-fluid" style={{ width: "100px" }} />
                                            </div>
                                            <h4 className="mb-2" style={{ color: "black" }}>{selectedPalestrante.palestrante}</h4>
                                            <p className="text-muted mb-4">{selectedPalestrante.doc}<span className="mx-2"></span>
                                                <br />
                                                <br />
                                                <p className="text-muted mb-0">{selectedPalestrante.minicurriculo.curriculo}</p>
                                            </p>


                                            <a type="button" data-bs-dismiss="modal" onClick={closeModal}>
                                                Voltar
                                            </a>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>





                </div>
            )}

        </div>
    );
}

export default Banner;
