import React from 'react';

function Transp() {
    return (
        <div id='Transfer' style={{ paddingTop: "100px", paddingBottom: "100px" }}>
            <div className='container' data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                <h1 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">TRANSPORTE</h1>
                <p data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">Serão oferecidos os seguintes trajetos para sua comodidade:</p>
            </div>
            <div className='container' >
                <div className="row">
                    <div className='col-12 col-sm-12 col-md-12 col-xl-6' data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div className="cardtransp"
                            style={{
                                backgroundColor: "white",
                                padding: "30px",
                                borderRadius: "50px",
                                width: "100%",
                                minHeight: "360px",
                                margin: "10px"
                            }}>
                            <div className="row">
                                <div className="col-12 d-flex flex-column justify-content-center" style={{ textAlign: "initial" }}>
                                    <h5 style={{ color: "black" }}><b>Aeroporto x Hotel x Aeroporto:</b></h5>
                                    <p style={{ color: "black" }}>
                                        <b>Transfer in:</b> Será disponibilizado um transfer, que estará identificado com uma placa personalizada com a logo GSK na saída do aeroporto para levá-lo até o seu hotel:

                                    </p>
                                    <p style={{ color: "black" }}>
                                        <b>Transfer out:</b> Pedimos a gentileza de consultar o Help Desk no foyer do seu hotel para mais informações.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-xl-6' data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div className="cardtransp"
                            style={{
                                backgroundColor: "white",
                                padding: "30px",
                                borderRadius: "50px",
                                width: "100%",
                                minHeight: "300px",
                                margin: "10px"
                            }}>
                            <div className="row">
                                <div className="col-12 d-flex flex-column justify-content-center" style={{ textAlign: "initial" }}>
                                    <h5 style={{ color: "black" }}><b>Hotel x Evento x Hotel:</b></h5>
                                    <p style={{ color: "black" }}>No dia 10/04 estará disponível um transfer para levá-lo dos hotéis: InterContinental, Qoya Hotel São Paulo, Radisson Hotel Paulista, Meliá Paulista, São Paulo Higienópolis Affiliated by Meliá e Hotel Renaissance até a Vila dos Ipês.
                                    </p>
                                    <b>A saída está programada para as 17h45 no foyer do seu hotel.</b>
                                    <p style={{ color: "black" }}>
                                        No dia 11/04, estará disponível um transfer dos hotéis InterContinental, Qoya Hotel São Paulo, Radisson Hotel Paulista, Meliá Paulista e São Paulo Higienópolis Affiliated by Meliá para o hotel Renaissance.
                                        A saída está programada para as 7h45 no foyer do hotel.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-xl-12' data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div className="cardtransp"
                            style={{
                                backgroundColor: "white",
                                padding: "30px",
                                borderRadius: "50px",
                                width: "100%",
                                minHeight: "300px",
                                margin: "10px"
                            }}>
                            <div className="row">
                                <div className="col-12 d-flex flex-column justify-content-center" style={{ textAlign: "initial" }}>
                                    <p style={{ color: "black" }}>
                                        <h5 style={{ color: "black" }}><b>IMPORTANTE!</b></h5>
                                    </p>
                                    <p style={{ color: "black" }}>
                                        ⦁	O serviço de transfer é exclusivo para os convidados patrocinados durante o período do evento;
                                        <br></br>
                                        ⦁	No dia 11/04, para os hóspedes dos hotéis InterContinental, Qoya Hotel São Paulo, Radisson Hotel Paulista, Meliá Paulista e São Paulo Higienópolis Affiliated by Meliá, pedimos a gentileza de fazerem o checkout com pelo menos 1 hora de antecedência da saída do transfer. Disponibilizaremos o serviço de maleiro no hotel Renaissance para guardar sua mala durante o evento;
                                        <br></br>
                                        ⦁	No dia 11/04, para os hóspedes do hotel Renaissance, recomendamos efetuar o checkout, de preferência, com 1 hora de antecedência ao início do evento;
                                        <br></br>
                                        ⦁	A GSK cobrirá os custos das diárias em quartos individuais, incluindo café da manhã e taxas. Quaisquer outras despesas extras deverão ser acertadas diretamente com o hotel no momento do checkout.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transp;
