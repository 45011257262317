import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import Navbar from './parts/navbar'
import Banner from './parts/banner'
import Sobre from './parts/sobre'
import Agenda from './parts/agenda'
import Palestrantes from './parts/palestrantes'
import Transportes from './parts/transportes';
import Footer from './parts/footer'
import End from './parts/end'
import Helpdesk from './parts/helpdesk'
import 'bootstrap/dist/js/bootstrap.bundle';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
function App() {
  return (
    <div className="App">
      <Navbar/>
      <Banner/>
      <Sobre/>
      <Agenda/>
      <Palestrantes/>
      <Transportes/>
      <End/>
      <Helpdesk/>
      <Footer/>
       

      
    </div>
  );
}

export default App;
