import React, { useState } from 'react';
import Agenda from './salas/agenda';

function Agendadois() {
    const [visibleDiv2, setvisibleDiv2] = useState('div1');

    const toggleDiv = (divId) => {
        if (visibleDiv2 === divId) {
            setvisibleDiv2(null); // Se a div atualmente visível é clicada novamente, oculta ela
        } else {
            setvisibleDiv2(divId); // Se uma nova div é clicada, mostra ela
        }
    };

    return (
        <div style={{ paddingTop: "20px" }}>
            <div>
                <div style={{ paddingBottom: "30px" }}>
                    <div className='row container-fluid text-center justify-content-center'>

                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div1' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div1')} disabled={visibleDiv2 === 'div1' ? true : false} data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Médicos</h4>
                                        <br></br>
                                        <text>Sala: Teatro Renaissance.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div2' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div2')} disabled={visibleDiv2 === 'div2' ? true : false} data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Gestores Clínica</h4>
                                        <text>Sala: Andes | Pampas e Patagonia.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div3' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div3')} disabled={visibleDiv2 === 'div3' ? true : false} data-aos="zoom-in-left" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Gestores Farmácia</h4>
                                        <text>Sala: Yukon.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div6' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div6')} disabled={visibleDiv2 === 'div6' ? true : false} data-aos="zoom-in-left" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Gestores Health leaders</h4>
                                        <text>Sala: Yukon.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div4' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div4')} disabled={visibleDiv2 === 'div4' ? true : false} data-aos="zoom-in-left" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Enfermeiros</h4>
                                        <text>Sala: América do Norte e América do Sul.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <button className={`${visibleDiv2 === 'div5' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div5')} disabled={visibleDiv2 === 'div5' ? true : false} data-aos="zoom-in-left" data-aos-delay="300" data-aos-duration="2000">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4>Farmacêuticos</h4>
                                        <text>Sala: América do Norte e América do Sul.</text>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                {visibleDiv2 === 'div1' && (
                    <Agenda props={'medicos'}/>
                )}
                {visibleDiv2 === 'div2' && (
                    <Agenda props={'gclinica'}/>
                )}
                {visibleDiv2 === 'div3' && (
                   <Agenda props={'gfarm'}/>
                )}
                  {visibleDiv2 === 'div6' && (
                   <Agenda props={'ghealth'}/>
                )}
                {visibleDiv2 === 'div4' && (
                   <Agenda props={'enf'}/>
                )}
                 {visibleDiv2 === 'div5' && (
                    <Agenda props={'farm'}/>
                )}
            </div>

            <br />
        </div>

    );
}

export default Agendadois;
