import React from 'react';

function Sobre() {
    return (
        <div style={{ backgroundColor: "#f5891d" }}>
            <br />
            <div className="container" >
                <div>
                    <h1 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000"><b >Seja bem-vindo ao evento Longevidade 360º da GSK</b></h1>
                    <br />
                    <h3 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000"><b>Prepare-se para uma experiência única e enriquecedora!</b></h3>
                    <br />
                </div>
                <div>
                    <h4 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">É com imenso prazer que estamos reunindo mais de 500 pessoas, de diferentes especialidades médicas e áreas da saúde, para aprender juntos, trocar informações, inspirar e refletir sobre um tema atual muito importante: longevidade e o envelhecimento saudável.</h4>
                </div>
                <br />
                <div>
                    <h4 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">Nesse hotsite dedicado ao evento Longevidade 360° da GSK, você terá acesso à agenda completa para conferir todos os detalhes dos conteúdos e dos palestrantes, informações sobre transporte e endereços dos dois dias de evento.</h4>
                </div>
                <br />
                <div>
                    <h3 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">Vamos juntos moldar um futuro mais saudável!</h3>
                </div>
            </div>
            <br />
        </div>
    );
}

export default Sobre;
