import React from 'react';
import imgBanner from '../assets/img/banner.jpg';

function Banner() {
    return (
        <div  data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
            <div style={{marginTop: "60px"}}><img className="gskbanner" src={imgBanner} alt="BG" /></div>
        </div>
    );
}

export default Banner;
