import React from "react";
import './navbar.css'
import Logo from '../assets/img/logo.png'

function Navbar() {
    return (
        <header>
            <nav className="navbar fixed-top navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#" data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="2000">
                        <img className="gsklogo" src={Logo} alt="Logo"></img>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav  ms-auto mb-2 mb-lg-0" data-aos="zoom-in-left" data-aos-delay="300" data-aos-duration="2000">
                            <li className="nav-item"><a className="nav-link navbar-brand" href="#agenda">
                                <div className="radius-curve">AGENDA</div>
                            </a></li>
                            <li className="nav-item"><a className="nav-link navbar-brand" href="#Palestrantes">
                                <div className="radius-curve">PALESTRANTES</div>
                            </a></li>
                            <li className="nav-item"><a className="nav-link navbar-brand" href="#Transfer">
                                <div className="radius-curve">TRANSPORTE</div>
                            </a></li>
                            <li className="nav-item"><a className="nav-link navbar-brand" href="#Endereco">
                                <div className="radius-curve">COMO CHEGAR</div>
                            </a></li>
                            <li className="nav-item"><a className="nav-link navbar-brand" href="#Helpdesk">
                                <div className="radius-curve">HELP DESK</div>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
