import React, { useState } from 'react';
import AgendaUm from './agendas/agendadivum'

import AgendaDois from './agendas/agendadivdois'
import Triangulo from '../assets/img/Polygon_1.png'

function Agenda() {
    const [visibleDiv, setVisibleDiv] = useState('div1');

    const toggleDiv = (divId) => {
        if (visibleDiv === divId) {
            setVisibleDiv(null); // Se a div atualmente visível é clicada novamente, oculta ela
        } else {
            setVisibleDiv(divId); // Se uma nova div é clicada, mostra ela
        }
    };

    return (
        <div id='agenda' style={{paddingTop: "100px"}}>
            <br />
            <div class="container" data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                <div class="agenda_title mx-auto aos-init aos-animate" style={{ backgroundColor: "#f5891d" }} data-aos="zoom-in-up" data-aos-delay="200" data-aos-duration="2000">
                    <p data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <h1 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">AGENDA:</h1>
                    </p>
                </div>
                <h6 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">Importante levar seu crachá nos dois dias de evento!</h6>
            </div>
            <div>
                <div style={{ paddingBottom: "30px" }}>
                    <button className={`${visibleDiv === 'div1' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div1')} disabled={visibleDiv === 'div1' ? true : false} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div className='row'>
                            <div className='col-2 d-flex justify-content-center align-items-start'>
                                <img className='triangulo' src={Triangulo}></img>
                            </div>
                            <div className='col-10'>
                                <h1>  10 DE ABRIL</h1>
                                <text>Vila dos Ipês
                                    <br></br>
                                    São Paulo</text>
                            </div>
                        </div>
                    </button>
                    <button className={`${visibleDiv === 'div2' ? 'ativo' : ''} nav-circle-gradient link `} onClick={() => toggleDiv('div2')} disabled={visibleDiv === 'div2' ? true : false} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div className='row'>
                            <div className='col-2 d-flex justify-content-center align-items-start'>
                                <img className='triangulo' src={Triangulo}></img>
                            </div>
                            <div className='col-10'>
                                <h1>  11 DE ABRIL</h1>
                                <text>Hotel Renaissance
                                    <br></br>
                                    São Paulo</text>
                            </div>
                        </div>
                    </button>
                </div>

                {visibleDiv === 'div1' && (

                    <AgendaUm/>

                )}

                {visibleDiv === 'div2' && (
                   <AgendaDois/>
                )}
            </div>

            <br />
        </div>

    );
}

export default Agenda;
