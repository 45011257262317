import React from 'react';

function Helpdesk() {
    return (
        <div id='Helpdesk' className="Fac" style={{ backgroundColor: "white" }}>
            <div className="container" style={{
                padding: "100px 0 100px 0px"
            }}>
                <p
                    data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000"
                >
                    <h1 style={{ color: "black" }} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">HELP DESK</h1>
                </p>
                <p
                    data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000" style={{ color: "black" }}
                >
                    <h5 style={{ color: "black" }} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        Disponibilizaremos um Help Desk no foyer dos hotéis InterContinental, Qoya Hotel São Paulo, Radisson Hotel Paulista, Meliá Paulista, São Paulo Higienópolis Affiliated by Meliá e Renaissance para oferecer todo suporte.
                        Fique à vontade para tirar suas dúvidas e pedir informações completas sobre os dois dias de evento.


                        <br />

                        <br />
                        A retirada dos crachás de credenciamento para o evento será no Help Desk de cada Hotel.
                        
                        <br />

                        <br />
                        A entrada no transfer só será autorizada mediante a apresentação do crachá.
                    </h5>
                </p>
            </div>
        </div>
    );
}

export default Helpdesk;
