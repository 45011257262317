import React from 'react';

function End() {
    return (
        <div className="session" id="Endereco" style={{backgroundColor:"#F5891D"}}>
            <div className="Endereco">
                <div className="container">
                    <p
                      data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000"
                        style={{ color: 'rgb(96, 172, 219)' }}
                        className="aos-init aos-animate"
                    >
                        <h1 data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000"><b>COMO CHEGAR</b></h1>
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000" >
                                <p style={{ lineHeight: '1' }}>
                                    <h1 style={{ lineHeight: '1' }}>Vila dos Ipês:
                                    <br></br>
                                        10-04-2024
                                    </h1>
                                </p>
                            </div>
                            <div className="mapcontainer">
                                <div id="display-google-map" className="display-google-map" data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                                    <iframe
                                        className="frame-map"
                                        src="https://www.google.com/maps/embed/v1/place?q=Avenida+Mofarrej,+1505 +-+Vila +Leopoldina,+São+Paulo+-+SP,+Brasil&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                                        title="Google Maps"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                        <div style={{ lineHeight: '1' }} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                                    <p style={{ lineHeight: '1' }}>
                                        <h1 style={{ lineHeight: '1' }}>Hotel Renaisssance:
                                        <br></br>
                                            11-04-2024
                                        </h1>
                                    </p>
                                </div>
                            <div className="mapcontainer aos-init aos-animate" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="2000">
                              
                                <div id="display-google-map" className="display-google-map">
                                    <iframe
                                        className="frame-map"
                                        src="https://www.google.com/maps/embed/v1/place?q=Alameda+Santos,+2233+-+Jardim+Paulista,+São+Paulo+-+SP,+01419-002,+Brasil&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                                        title="Google Maps"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default End;
