import React from 'react';
import Gsk_Footer from '../assets/img/gsk_footer.png';
//import Veja_Footer from '../assets/img/veja_saude_footer.png';
import Farmaco_Footer from '../assets/img/farmaco_vigilancia.png';

function Banner() {
    return (
        <>
            <div className="Footer" style={{ paddingTop: "100px", paddingBottom: "100px" }} data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="2000">
                <div className="container">
                    <div className="footer_div">
                        <div className="footer_text">
                            {/* <h1><b>Uma parceria inédita:</b></h1> */}
                            <img style={{ paddingTop: "20px", paddingBottom: "20px", }} className="gsk" alt="GSK logo" src={Gsk_Footer} />
                            <br></br>
                            <p>
                                Este material é de propriedade da GSK, a reprodução deste é proibida sem o consentimento da empresa.
                            </p>
                            <p>
                                Informação destinada a profissionais de saúde em geral,gestores de clínicas e farmácias, sem cunho publicitário.
                            </p> 
                            
                            Mais informações à disposição, sob solicitação ao Departamento de Informações Médicas 
                            (<b>DDG <a href='tel:+08007012233' target="_blank" style={{color:"white"}}>0800 701 2233</a></b> ou <b> <a  href='mailto:medinfo@gsk.com' target="_blank" style={{color:"white"}}>medinfo@gsk.com</a></b>). 
                            Para notificar informações de segurança, incluindo eventos adversos, ocorridos durante o uso de medicamentos/vacinas da GSK
                            , entre em contato diretamente com o Departamento de Farmacovigilância da empresa através da nossa plataforma eletrônica 
                            
                           <b> <a href='https://gsk.public.reportum.com/' target="_blank" style={{color:"white"}}> https://gsk.public.reportum.com/</a></b>
                            , pelo e-mail <b><a href='mailto:farmacovigilancia@gsk.com' target="_blank" style={{color:"white"}}>farmacovigilancia@gsk.com </a> </b>ou por meio do representante do grupo de empresas GSK.
                            As marcas registradas pertencem ou são licenciadas ao grupo de empresas GSK.


                                <br />
                                <p>
                                    <b>NP-BR-HZU-WCNT-240003 MAR/2024</b>
                                </p>
                                <br />
                                <img className='gsk_' alt="GSK_Logo" style={{ paddingBottom: "40px" }} src={Farmaco_Footer} />
                                <br />
                                <p>
                                    <span>© 2021 GlaxoSmithKline Brasil Ltda. Todos os direitos reservados.</span>
                                    <br /><br />
                                    <span>As marcas registradas pertencem ou são licenciadas ao grupo de empresas GSK. Estrada dos Bandeirantes 8464 – Rio de Janeiro – RJ CEP 22783-110</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            );
}

            export default Banner;
